import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import ErrorMessage from 'elements/error-message';
import { toNumber } from 'lodash';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  font-size: 1.5rem;
  line-height: 2.4rem;

  ${({ cssRules }) => cssRules &&
    css`
      ${cssRules}
    `}
`;

const TextInput = ({
  name,
  withError,
  isLocked,
  hasChanges,
  inputValue,
  isDisabled,
  onChange,
  placeholder,
  label,
  withLabel,
  isRequired,
  hasManuallyUpdates,
  error,
  withWarning,
  cssRules,
  withErrorBox,
  validationRules,
  autoFocus,
  format,
}) => {
  const {
    minValue,
    maxValue,
    isRates,
    decimalScale,
    isInteger,
    customValidation,
    allowNegative,
    isPercentage,
  } = validationRules;

  const onValueChange = ({ value }) => {
    if (
      ((!value || !inputValue) && value !== inputValue) ||
      toNumber(value) !== toNumber(inputValue)
    ) {
      //
      const valueToNumber = toNumber(value);

      onChange(Number.isNaN(valueToNumber) || value === '' ? null : valueToNumber);
    }
  };

  const getIsAllowed = ({ floatValue }) => {
    if (customValidation) {
      return customValidation(floatValue);
    }
    if (isLocked ||
      floatValue > maxValue ||
      floatValue < minValue) {
      return false;
    }
    return true;
  };

  const isLabelVisible = !!inputValue || inputValue === 0 || inputValue === '0';
  const tabIndex = isLocked || isDisabled ? -1 : 0;
  const isFixedDecimalScale = isRates; //  && !!inputValue
  const isAllowedNegative = !!allowNegative;

  return (
    <StyledContainer
      className={classNames(
        'text-input',
        { 'text-input--with-error': withError },
        { 'text-input--has-changes': hasChanges },
        { 'text-input--locked': isLocked },
        { 'text-input--has-changes-locked': isLocked && hasChanges },
        { 'text-input--with-warning-locked': isLocked && withWarning }
      )}
      cssRules={cssRules}
    >
      <NumberFormat
        id={name}
        name={name}
        inputMode="numeric"
        className={classNames(
          'text-input__input-field',
          { 'text-input__input-field--with-error': withError },
          { 'text-input__input-field--with-changes': hasChanges },
          { 'text-input__input-field--with-warning': withWarning }
        )}
        autoComplete="off"
        placeholder={placeholder}
        tabIndex={tabIndex}
        value={inputValue}
        onValueChange={onValueChange}
        isAllowed={getIsAllowed}
        fixedDecimalScale={isFixedDecimalScale}
        allowNegative={isAllowedNegative}
        format={format}
        decimalScale={isInteger ? 0 : decimalScale || 2}
        thousandSeparator
        autoFocus={autoFocus}
        {...(isPercentage && { suffix: '%' })}
      />

      {withLabel && (
        <label
          className={classNames(
            'text-input__label',
            { 'text-input__label--visible': isLabelVisible },
            { 'text-input__label--with-error': withError || hasManuallyUpdates }
          )}
          htmlFor={name}
        >
          {label}
          {!isRequired && (
            <span className="text-input__optional-label">&nbsp;(optional)</span>
          )}
        </label>
      )}

      {withError && !isLocked && !withErrorBox && (
        <ErrorMessage>{error}</ErrorMessage>
      )}
    </StyledContainer>
  );
};

TextInput.propTypes = {
  withErrorBox: PropTypes.bool,
  cssRules: PropTypes.string,
  withWarning: PropTypes.bool,
  name: PropTypes.string.isRequired,
  withError: PropTypes.bool,
  isLocked: PropTypes.bool,
  hasChanges: PropTypes.bool,
  autoFocus: PropTypes.bool,
  format: PropTypes.string,
  inputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  withLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  hasManuallyUpdates: PropTypes.bool,
  error: PropTypes.string,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};

TextInput.defaultProps = {
  withErrorBox: false,
  cssRules: '',
  withWarning: false,
  withError: false,
  isLocked: false,
  hasChanges: false,
  isDisabled: false,
  inputValue: '',
  onChange: () => null,
  placeholder: '',
  label: '',
  withLabel: true,
  isRequired: true,
  hasManuallyUpdates: false,
  error: '',
  validationRules: {},
  autoFocus: false,
  format: null,
};

export default TextInput;
