import { waitForAuthorization } from 'core/auth/sagas';
import { modalConductorActions } from 'core/modal-conductor/actions';
import gql from 'graphql-tag';
import hash from 'hash-sum';
import { get } from 'lodash';
import {
  all,
  put,
  call,
  delay,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { parseError, executeQuery, executeMutation } from 'utils/request';

import { checkPermissionByError } from 'utils/request/error';

import {
  employeesReviewsActions,
  employeesReviewsActionsTypes,
} from './actions';

import queryConfig from './queries';
import {
  selectErrors,
  selectEntityName,
  selectSelectedDate,
  selectSelectedEmployee,
} from './selectors';

import { getCorrectYear } from './utils';

function* notifyProjectLead({ payload: { projectId } }) {
  try {
    const selectedDate = yield select(selectSelectedDate);
    const options = {
      mutation: queryConfig.notifyProjectLead,
      variables: {
        fields: {
          projectId,
          week: selectedDate.week(),
          year: selectedDate.year(),
        },
      },
    };
    const {
      devstaffReviewRequiredNotification: { notificationDatetime },
    } = yield call(executeMutation, options);

    return yield put(employeesReviewsActions.notifyProjectLeadSuccess({
      projectId,
      notificationDatetime,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'notifyProjectLeadError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const notifyProjectLeadError = yield call(parseError, error, options);

    return yield all([
      put(employeesReviewsActions.notifyProjectLeadFail({
        error: {
          notifyProjectLeadError,
        },
      })),
      put(modalConductorActions.clearModal()),
    ]);
  }
}

function* sendPdfReviews({ payload, meta: { entityName } }) {
  try {
    const options = {
      mutation: queryConfig.devstaffReviewSendPdfReport,
      variables: payload,
    };
    yield call(executeMutation, options);
    return yield put(employeesReviewsActions.sendPdfReviewsSuccess());
  } catch (error) {
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'sendPdfReviewsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const sendPdfReviewsError = yield call(parseError, error, options);

    return yield put(employeesReviewsActions.sendPdfReviewsFail({
      error: {
        sendPdfReviewsError,
      },
    }));
  }
}

function* getPdfReviews({ payload }) {
  try {
    const options = {
      mutation: queryConfig.generatePDF,
      variables: {
        fields: payload,
      },
    };

    const response = yield call(executeMutation, options);
    const url = get(response, 'devstaffReviewPdfReport.url');
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(employeesReviewsActions.getPdfReviewsSuccess());
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getPdfReviewsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getPdfReviewsError = yield call(parseError, error, options);

    return yield put(employeesReviewsActions.getPdfReviewsFail({
      error: {
        getPdfReviewsError,
      },
    }));
  }
}

function* getReviewsCommonData() {
  try {
    const query = queryConfig.getReviewsCommonData;
    const options = {
      query,
    };
    const { projectsList, employeesList } = yield call(executeQuery, options);

    return yield put(employeesReviewsActions.getReviewsCommonDataSuccess({
      projectsList,
      employeesList,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getReviewsCommonDataError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getReviewsCommonDataError = yield call(parseError, error, options);

    return yield put(employeesReviewsActions.getReviewsCommonDataFail({
      error: {
        getReviewsCommonDataError,
      },
    }));
  }
}

function* getReviews() {
  yield delay(600);
  const selectedDate = yield select(selectSelectedDate);

  try {
    const query = queryConfig.getReviews;
    const options = {
      query,
      variables: {
        week: selectedDate.week(),
        year: selectedDate.weekYear(),
      },
    };
    const { reviews, otherReviews, notificationsLog } = yield call(
      executeQuery,
      options
    );

    return yield put(employeesReviewsActions.getReviewsSuccess({
      reviews,
      otherReviews,
      notificationsLog,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getReviewsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getReviewsError = yield call(parseError, error, options);

    return yield put(employeesReviewsActions.getReviewsFail({
      error: {
        getReviewsError,
      },
    }));
  }
}

function* selectEmployeeReviews() {
  const devstaffId = yield select(selectSelectedEmployee);

  try {
    const query = queryConfig.devstaffReviewsReport;
    const options = {
      query,
      variables: {
        devstaffId,
      },
    };
    const { devstaffReviewsReport } = yield call(executeQuery, options);

    return yield put(employeesReviewsActions.selectEmployeeSuccess(devstaffReviewsReport));
  } catch (error) {
    checkPermissionByError(error);

    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'selectEmployeeError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const selectEmployeeError = yield call(parseError, error, options);

    return yield put(employeesReviewsActions.selectEmployeeFail({
      error: {
        selectEmployeeError,
      },
    }));
  }
}

function* submitReviews({ payload }) {
  try {
    const {
      roleUpdates,
      addedReviews,
      blockedReviews,
      updatedReviews,
      deletedReviews,
      unlockedRecords,
    } = payload;
    const selectedDate = yield select(selectSelectedDate);
    const week = selectedDate.week();
    const year = getCorrectYear(selectedDate);
    let mutationArguments = '';
    let mutationBody = '';
    let notify = false;
    const variables = {};
    const blockReviewsData = [];
    const updateReviewsData = [];
    const deleteReviewsData = [];
    const blocked = new Set();
    const willBeDeleted = new Set();
    const updated = new Set();

    addedReviews.forEach((
      {
        id,
        recordId,
        isToBlock,
        initialRole,
        devstaffName,
        hasRoleChanged,
        isInitialBlocked,
        project,
        ...rest
      },
      index
    ) => {
      mutationArguments += ` $addedFields${index}: AddReviewInput!`;
      mutationBody += `
      createReview${index}: createReview(fields: $addedFields${index}) {
        ok
        id
      }`;

      variables[`addedFields${index}`] = {
        ...rest,
        week,
        year,
        ...project,
      };
    });

    deletedReviews.forEach(({ id, recordId, isToBlock, devstaffId, blockReason, project }) => {
      willBeDeleted.add(recordId);
      if (isToBlock) {
        notify = true;
        if (!blocked.has(id)) {
          blocked.add(id);
          blockReviewsData.push({
            week,
            year,
            devstaffId,
            block: true,
            blockReason: blockReason || '',
            ...project,
          });
        }
      } else {
        deleteReviewsData.push(recordId);
      }
    });

    blockedReviews.forEach(({ devstaffId, blockReason, project }) => {
      blockReviewsData.push({
        week,
        year,
        devstaffId,
        block: true,
        blockReason: blockReason || '',
        ...project,
      });
    });
    unlockedRecords.forEach(({ devstaffId, project }) => {
      blockReviewsData.push({
        week,
        year,
        devstaffId,
        block: false,
        blockReason: '',
        ...project,
      });
    });
    updatedReviews.forEach(({
      isPl,
      role,
      review,
      rating,
      reviewby,
      recordId,
      isPlReview,
      jiraProject,
      reviewbyId = 0,
      isCustomerReview,
      isAdminStaffReview,
      project,
    }) => {
      if (willBeDeleted.has(recordId)) {
        return null;
      }
      const id = hash({
        recordId,
        jiraProject,
      });
      updated.add(id);
      return updateReviewsData.push({
        recordId,
        fields: {
          isPl,
          role,
          review,
          rating,
          reviewby,
          reviewbyId,
          isPlReview,
          isCustomerReview,
          isAdminStaffReview,
          ...project,
        },
      });
    });

    roleUpdates.forEach(({ role, recordId, jiraProject }) => {
      if (recordId) {
        if (willBeDeleted.has(recordId)) {
          return null;
        }
        const id = hash({
          recordId,
          jiraProject,
        });
        if (!updated.has(id)) {
          updateReviewsData.push({
            recordId,
            fields: {
              role,
            },
          });
        }
      }
      return null;
    });

    const options = {
      mutation: queryConfig.manageReviews,
      variables: {
        week,
        year,
        notify:
          notify || !!deleteReviewsData.length || !!updateReviewsData.length,
        blockReviewsData,
        updateReviewsData,
        deleteReviewsData,
      },
    };

    yield call(executeMutation, options);

    if (addedReviews.length) {
      const addedReviewsOptions = {
        mutation: gql`
          mutation submitReviews(${mutationArguments}) {
            ${mutationBody}
          }
        `,
        variables,
      };

      yield call(executeMutation, addedReviewsOptions);
    }

    yield call(getReviews);

    return yield put(employeesReviewsActions.submitReviewsSuccess({}));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'submitReviewsError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const submitReviewsError = yield call(parseError, error, options);

    return yield put(employeesReviewsActions.submitReviewsFail({
      error: {
        submitReviewsError,
      },
    }));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(
      employeesReviewsActionsTypes.NOTIFY_PROJECT_LEAD,
      waitForAuthorization(notifyProjectLead)
    ),
    yield takeLatest(
      employeesReviewsActionsTypes.GET_PDF_REVIEWS,
      waitForAuthorization(getPdfReviews)
    ),
    yield takeLatest(
      employeesReviewsActionsTypes.SEND_PDF_REVIEWS,
      waitForAuthorization(sendPdfReviews)
    ),
    yield takeLatest(
      employeesReviewsActionsTypes.SELECT_EMPLOYEE,
      waitForAuthorization(selectEmployeeReviews)
    ),
    yield takeLatest(
      [
        employeesReviewsActionsTypes.GET_REVIEWS,
        employeesReviewsActionsTypes.SET_DATE,
      ],
      waitForAuthorization(getReviews)
    ),
    yield takeEvery(
      employeesReviewsActionsTypes.SUBMIT_REVIEWS,
      waitForAuthorization(submitReviews)
    ),
    yield takeEvery(
      employeesReviewsActionsTypes.GET_REVIEWS_COMMON_DATA,
      waitForAuthorization(getReviewsCommonData)
    ),
  ]);
}
