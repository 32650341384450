import { colorSecondaryText } from 'assets/styles/variables';
import { taskOrdersActions } from 'core/task-orders/actions';
import { getDefaultText, isPTMContract } from 'layouts/task-order-details/utils';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { emailRegex } from 'utils/email';

import { formatDateYYYYMMDD } from 'utils/helpers/date';
import { stringCompareFunction } from 'utils/helpers/sorting';
import { number, string } from 'yup';

const { getCommittedHoursByClientId, resetCommittedHoursByClientId } = taskOrdersActions;

export default ({
  isCEO,
  isCTO,
  isCPS,
  isHRM,
  clientData,
  isCreatMode,
  clientsList,
  taskOrderId,
  isTaskOrderActive,
  isManuallyDeactivated,
  status,
  selectCommittedHoursByClientId,
  staffEmployees,
  isPTM,
}) => {
  const { notYetActive, isActive, isExpired } = status;
  const activeClientClientId = get(clientData, 'clientId');

  clientsList.sort((a, b) => stringCompareFunction(a.label, b.label));

  const activeClients = clientsList.filter(({ msa, isActive: isActiveClient, isSignedMsaUploaded }) => isActiveClient && msa && isSignedMsaUploaded);

  return [
    {
      type: 'form',
      stylesTemplate: {
        cssRules: `
          grid-template-columns: repeat(5, 1fr);
          grid-template-areas: "projectInfo projectInfo projectInfo toInfoLeft toInfoRight" "description description description description description";
          grid-column-gap: 1.8rem;
        `,
        formControlsCss:
          'grid-template-columns: repeat(5, calc((136rem - 4.8rem * 4 - 3.2rem) / 5));',
      },
      withoutFormControls: isManuallyDeactivated && !isCEO,
      content: [
        {
          title: 'Project Info',
          stylesTemplate: {
            gridArea: 'projectInfo',
            marginConfig: 'n',
            cssRules: `
              grid-template-columns: minmax(0, 1fr);
            `,
          },
          formData: [
            {
              isMultiple: true,
              cssRules: `
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-areas: "clientName clientName msa";
                grid-column-gap: 0;
              `,
              formData: [
                {
                  gridArea: 'clientName',
                  type: 'select',
                  name: 'clientId',
                  getIsUnitLocked: () => !isCreatMode,
                  placeholder: 'Client Name',
                  valueGetter: ({ client }) => {
                    const value = get(client, 'clientId', activeClientClientId);
                    return {
                      selected: { value },
                      items: isCreatMode ? activeClients : clientsList,
                      isLink: !isCreatMode,
                      path: `/clients/${value}/${
                        isCTO || isHRM ? 'task-orders' : 'details'
                      }`,
                    };
                  },
                  validationRules: {
                    isRequired: true,
                    schemaGetter: () => string().required('Required field'),
                  },
                  cssRules: `
                    grid-area: clientName;
                    width: 105%;
                    & .custom-link a {
                      font-size: 1.5rem;
                    }
                  `,
                },
                {
                  gridArea: 'msa',
                  marginConfig: '0 0 0 4.6rem',
                  type: 'text',
                  withLabel: true,
                  name: 'msaName',
                  placeholder: 'MSA#',
                  getIsUnitLocked: () => true,
                  valueGetter: ({ client, msaName: effectiveMSAOnTaskOrderCreation }) => {
                    if (!isCreatMode) return { value: effectiveMSAOnTaskOrderCreation };
                    const clientId = client ? client.clientId : '';
                    const { msa } =
                    clientsList.find(({ clientId: clientName }) => clientName === clientId) || {};

                    return {
                      value: msa,
                    };
                  },
                  parentField: {
                    name: ['clientId'],
                    valueGetter: ({ clientId, msaName }) => {
                      if (!isCreatMode) return { value: msaName };
                      const { msa } = clientsList.find(({ clientId: clientName }) => clientName === clientId) || {};
                      return {
                        value: msa,
                      };
                    },
                  },
                  cssRules: `
                    grid-area: msa;
                    margin-left: 4.4rem;
                  `,
                  validationRules: {
                    isRequired: true,
                  },
                },
              ],
            },
            {
              type: 'text',
              withLabel: true,
              name: 'title',
              placeholder: 'Descriptive Title / Project Name',
              valueGetter: ({ title }) => ({
                value: title || '',
              }),
              getIsUnitLocked: () => (isManuallyDeactivated && !isCEO) ||
                (isTaskOrderActive && isCTO) ||
                isCPS,
              validationRules: {
                isRequired: true,
                maxLength: 50,
                schemaGetter: () => string()
                  .max(
                    50,
                    'Descriptive Title / Project Name max length is 50'
                  )
                  .matches(/^((?![а-яА-ЯёЁ]).)*$/, 'Wrong format')
                  .required('Required field'),
              },
            },
            {
              type: 'text',
              withLabel: true,
              name: 'clientPm',
              placeholder: 'Client PM Name',
              getIsUnitLocked: () => (isManuallyDeactivated && !isCEO) ||
                (isTaskOrderActive && isCTO) ||
                isCPS,
              valueGetter: ({ clientPm }) => ({
                value: clientPm || '',
              }),
              validationRules: {
                maxLength: 50,
                schemaGetter: () => string()
                  .max(50, 'Client PM Name max length is 50')
                  .matches(/^((?![а-яА-ЯёЁ]).)*$/, 'Wrong format'),
              },
            },
            {
              type: 'text',
              withLabel: true,
              name: 'emailReports',
              placeholder: 'Send reports to (emails separated by ,)',
              getIsUnitLocked: () => (isManuallyDeactivated && !isCEO) || isCPS,
              valueGetter: ({ emailReports }) => ({
                value: emailReports || '',
              }),
              validationRules: {
                maxLength: 200,
                schemaGetter: () => string()
                  .max(200, 'Emails max length is 200')
                  .transform((value) => value.trim())
                  .test('isAllEmails', 'Wrong Emails format', (value) => {
                    if (!value) {
                      return true;
                    }
                    const emailsList = value.split(',');
                    return !emailsList.some((email) => !email.trim().match(emailRegex));
                  }),
              },
            },
          ],
        },
        {
          title: 'TO Info',
          stylesTemplate: {
            gridArea: 'toInfoLeft',
            cssRules: `
              grid-template-columns: minmax(0, 1fr);
            `,
          },
          formData: [
            {
              type: 'text',
              withLabel: true,
              name: 'taskOrderId',
              placeholder: 'Task Order#',
              isHidden: !taskOrderId,
              valueGetter: () => ({
                value: taskOrderId,
              }),
              getIsUnitLocked: () => true, // always locked
              validationRules: {
                isRequired: true,
              },
            },
            {
              type: 'select',
              name: 'projectType',
              placeholder: 'Contract Type',
              getIsUnitLocked: () => !isCreatMode &&
                (notYetActive ||
                  isActive ||
                  isExpired ||
                  (isManuallyDeactivated && !isCEO)),
              valueGetter: ({ projectType }) => ({
                selected: {
                  value: projectType || '',
                  label: projectType || '',
                },
                items: ['ODC', 'PTM', 'TTM'].map((type) => ({
                  value: type,
                  label: type,
                })),
              }),
              validationRules: {
                isRequired: true,
                schemaGetter: () => string().required('Required field'),
              },
            },
            {
              type: 'text',
              withLabel: true,
              name: 'cap',
              placeholder: 'Cap [hrs]',
              valueGetter: ({ cap, projectType }) => ({
                value:
                  !projectType || !isPTMContract(projectType) ? '' : cap || '',
              }),
              getIsUnitLocked: () => (isManuallyDeactivated && !isCEO) || isCTO || isTaskOrderActive,
              parentField: {
                name: ['projectType'],
                strictDependence: true,
                valueGetter: ({ projectType, cap }) => ({
                  value:
                    projectType && isPTMContract(projectType) && cap ? cap : '',
                  isNullable: true,
                  props: {
                    isLocked: !projectType || !isPTMContract(projectType),
                  },
                }),
              },
              validationRules: {
                isNumeric: true,
                maxValue: 9999999999.99,
                schemaGetter: () => number()
                  .nullable()
                  .min(0.1, "Cap can't be 0")
                  .max(9999999999.99, 'Cap max value is 9999999999'),
              },
            },
            {
              type: 'asyncSelect',
              name: 'committedHoursId',
              placeholder: 'Committed Hours',
              getIsUnitLocked: () => !isCreatMode,
              valueGetter: ({ committedHours }, { selected, items }) => {
                const selectedValue = committedHours ? {
                  value: committedHours.value,
                  label: committedHours.label,
                } : selected;

                const isSelectedValueEmpty = isEmpty(selectedValue);

                return ({
                  selected: isSelectedValueEmpty ? {
                    value: '',
                    label: '',
                  } : selectedValue,
                  items: isEmpty(items) ? [] : items,
                });
              },
              isCreatable: isCreatMode,
              parentField: {
                name: ['clientId', 'projectType'],
                valueGetter: ({ clientId, projectType }) => ({
                  isLocked: !isPTMContract(projectType) || !clientId || !isCreatMode,
                  isCleanFormValue: isCreatMode,
                  actionParams: {
                    clientId,
                  },
                }),
              },
              selector: selectCommittedHoursByClientId,
              action: getCommittedHoursByClientId,
              resetAction: resetCommittedHoursByClientId,
              isEditDisabled: true,
            },
            {
              type: 'text',
              withLabel: true,
              name: 'pono',
              placeholder: 'PO#',
              valueGetter: ({ pono }) => ({
                value: pono || '',
              }),
              isHidden: isCreatMode || !(clientData && clientData.poRequired),
              parentField: {
                name: ['clientId'],
                valueGetter: ({ clientId }) => {
                  const { poRequired } =
                    clientsList.find(({ clientId: clientName }) => clientName === clientId) || {};

                  return {
                    value: '',
                    props: {
                      isLocked: !poRequired,
                    },
                  };
                },
              },
              getIsUnitLocked: () => isCTO,
              validationRules: {},
            },
          ],
        },
        {
          title: '',
          stylesTemplate: {
            columns: '1fr',
            gridArea: 'toInfoRight',
            marginTop: '4.4rem',
          },
          formData: [
            {
              type: 'calendar',
              name: 'issueDate',
              placeholder: 'Issue Date',
              valueGetter: ({ issueDate }) => isCreatMode ? formatDateYYYYMMDD() : issueDate,
              outputFormatting: ({ date }) => moment.isMoment(date) ? date.format('YYYY-MM-DD') : date,
              getIsUnitLocked: () => (isManuallyDeactivated && !isCEO) ||
                isCTO ||
                isCPS ||
                isTaskOrderActive,
              validationRules: {
                schemaGetter: () => string().nullable().required('Required field'),
              },
              cssRules: `
              align-items: center;
              max-height: unset;
              font-size: 1.5rem;
              color: ${colorSecondaryText};
            `,
            },
            {
              type: 'calendar',
              name: 'effectiveDate',
              label: 'Effective Date',
              placeholder: 'Effective Date',
              valueGetter: ({ effectiveDate }) => effectiveDate || null,
              outputFormatting: ({ date }) => moment.isMoment(date) ? date.format('YYYY-MM-DD') : date,
              getIsUnitLocked: () => (isManuallyDeactivated && !isCEO) ||
                isCTO ||
                isCPS ||
                isTaskOrderActive,
              validationRules: {
                schemaGetter: () => isPTM ? string().nullable() : string().test('Effective date', 'cannot be later than "From" date in staffing.', (value) => {
                  if (!Array.isArray(staffEmployees) || staffEmployees.length === 0) {
                    return true;
                  }
                  const effective = moment(value, 'YYYY-MM-DD', true);
                  const hasInvalidDate = staffEmployees.some((employee) => {
                    const dateFrom = moment(employee.dateFrom, 'YYYY-MM-DD', true);
                    return !dateFrom.isValid() || dateFrom.isBefore(effective);
                  });
                  return !hasInvalidDate;
                }).nullable().required('Required field'),
              },
              cssRules: `
              align-items: center;
              max-height: unset;
              font-size: 1.5rem;
              color: ${colorSecondaryText};
            `,
            },
            {
              type: 'calendar',
              name: 'endDate',
              placeholder: 'Expiration Date',
              valueGetter: ({ endDate }) => endDate || null,
              outputFormatting: ({ date }) => moment.isMoment(date) ? date.format('YYYY-MM-DD') : date,
              getIsUnitLocked: () => isCTO ||
                (!isCEO && isManuallyDeactivated) ||
                (isTaskOrderActive && isCTO) ||
                isCPS,
              validationRules: {
                schemaGetter: () => string().nullable().required('Required field'),
              },
              cssRules: `
              align-items: center;
              max-height: unset;
              font-size: 1.5rem;
              color: ${colorSecondaryText};
            `,
            },
          ],
        },
        {
          title: 'Description',
          stylesTemplate: {
            columns: '1fr',
            gridArea: 'description',
            marginTop: '1.5rem',
          },
          controls: [
            {
              title: 'Add Default Text',
              onFormChange: ({ values: { projectType } }) => ({
                isHidden:
                  (isManuallyDeactivated && !isCEO) ||
                  isCTO ||
                  isTaskOrderActive ||
                  !projectType ||
                  isCPS,
              }),
              onClick: ({ setFieldValue, values }) => {
                const defaultText = getDefaultText(values);
                setFieldValue('description', defaultText);
              },
            },
          ],
          formData: [
            {
              type: 'text',
              withLabel: true,
              name: 'description',
              placeholder: 'Description',
              isMultiline: true,
              getIsUnitLocked: () => (isManuallyDeactivated && !isCEO) ||
                isCTO ||
                isTaskOrderActive ||
                isCPS,
              valueGetter: ({ description }) => ({
                value: description || '',
              }),
              parentField: {
                name: ['projectType'],
                valueGetter: ({ description, projectType }) => ({
                  value: description || '',
                  isNullable: true,
                  props: {
                    isLocked: !projectType,
                  },
                }),
              },
              validationRules: {
                maxLength: 5000,
                schemaGetter: () => string().max(5000, 'Description max value is 5000'),
              },
            },
          ],
        },
      ],
    },
  ];
};
