import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import {
  backgroundButtons,
  colorPrimary,
  colorSecondaryGrayLight,
  colorWhite,
  shadowButtons,
} from 'assets/styles/variables';

import Loader from 'components/loader';
import RemoteControllerButton from 'elements/remote-controller-button';
import { RemoteFormsController } from 'utils/helpers/forms';
import classNames from 'classnames';
import DetailsForm from 'components/details-form';
import SingleTable from 'components/details-table/single-table';
import Controls from 'components/list-controls';
import { commonActions } from 'core/common/actions';
import { configurationsActions } from 'core/configurations/actions';
import { selectExchangeRatesManagementFormContent } from 'core/configurations/selectors';
import GoTop from 'elements/go-top-button';
import Wrapper from 'elements/wrapper';
import { differenceWith, isEqual, findIndex } from 'lodash';
import { createStructuredSelector } from 'reselect';

import { autoScrollToTop } from 'utils/react';

import {
  exchangeRatesTableHeaderModel,
} from './model';

import {
  wrapperCssRules,
  formWrapperStylesTemplate,
  formMainControlsConfig,
} from './styles';

const ExchangeRatesManagement = ({
  location,
  entityName,
  isFetching,
  isFormSubmitted,
  getExchangeRates,
  updateExchangeRates,
  exchangeRatesManagementFormContent,
  changeOrder,
  orderRules,
}) => {
  autoScrollToTop(location);

  useEffect(() => {
    getExchangeRates();
  }, []);

  const onSubmit = ({ fields, initialValues }) => {
    const changedFields = differenceWith(fields.exchangeRates, initialValues.exchangeRates, isEqual);
    const updateData = changedFields.flatMap((field) => {
      const year = field.year;
      const initialValuesYearIndex = findIndex(initialValues.exchangeRates, { year });
      const initialValuesYearMonthData = initialValues.exchangeRates[initialValuesYearIndex].monthData;
      const changedMonthData = field.monthData.filter((month, index) => {
        // eslint-disable-next-line no-param-reassign
        month.year = year;
        return initialValuesYearMonthData[index].usdRur !== month.usdRur || initialValuesYearMonthData[index].usdPln !== month.usdPln;
      });
      return changedMonthData;
    });
    return updateExchangeRates({ fields: updateData });
  };

  const formsController = useMemo(() => new RemoteFormsController(({ formsState }) => {
    onSubmit({
      fields: formsState[0].values,
      initialValues: formsState[0].initialValues,
    });
  }));

  const tableRules = `{
    position: sticky;
    top:6.6rem;
    z-index: 1;
  }`;

  return (
    <>

      <Controls
        title="Exchange Rates Management"
        cssRules={`
          display: flex;
          flex-direction: row !important;
          align-items: center;
          justify-content: space-between !important;

          .controls__additional-controls {
            position: relative;
            margin: 0;
          }

          .transparent {
            opacity: 0;
          }
        `}
      >

        {isFormSubmitted && <Loader />}
        <div
          className={classNames({
            transparent: isFormSubmitted,
          })}
        >
          <RemoteControllerButton
            isFormSubmitted={false}
            getControlsState={formsController.getControlsState}
            registerControl={formsController.registerControl}
            configGetter={({
              isFormSubmitted: isSubmitted = false,
              hasFormChanges = false,
              defaultConfig,
              currentConfig,
            }) => {
              const defaultCssRules = defaultConfig.cssRules;
              const activeCssRules = `
                ${defaultCssRules}
                border-color: ${isSubmitted ? 'transparent' : colorPrimary};
                color: ${colorWhite};
                background-color: ${isSubmitted ? 'transparent' : colorPrimary};
                pointer-events: all;
                opacity: 1;
                ${isSubmitted ?
      `
                cursor: initial;` :
      `&:hover {
                    border-color: ${colorPrimary};
                    color: ${colorWhite};
                    background: ${backgroundButtons};
                    box-shadow: ${shadowButtons};
                  }
                `}
              `;

              return {
                ...currentConfig,
                cssRules:
                  hasFormChanges || isSubmitted ?
                    activeCssRules :
                    defaultCssRules,
                onClick:
                  hasFormChanges && !isSubmitted ?
                    formsController.submitForms :
                    () => null,
              };
            }}
            defaultConfig={{
              title: 'Save changes',
              cssRules: `
                min-width: 16.8rem;
                max-height: 3.2rem;
                margin-top: 0.6rem;
                padding: 0.7rem 2.4rem;
                border-color: ${colorSecondaryGrayLight};
                color: #fff;
                background-color: ${colorSecondaryGrayLight};
                opacity: 0.6;
                pointer-events: none;
              `,
            }}
          />

          <RemoteControllerButton
            isFormSubmitted={false}
            getControlsState={formsController.getControlsState}
            registerControl={formsController.registerControl}
            configGetter={({
              hasFormChanges = false,
              defaultConfig,
              currentConfig,
            }) => {
              const defaultCssRules = defaultConfig.cssRules;
              const activeCssRules = `
                    ${defaultCssRules}
                    border-color: ${colorPrimary};
                    color: ${colorPrimary};
                    pointer-events: all;
                    opacity: 1;
                  `;
              return {
                ...currentConfig,
                cssRules: hasFormChanges ? activeCssRules : defaultCssRules,
                onClick: hasFormChanges ?
                  formsController.resetForm :
                  () => null,
              };
            }}
            defaultConfig={{
              title: 'Cancel',
              cssRules: `
                    min-width: 16.8rem;
                    max-height: 3.2rem;
                    margin-top: 0.6rem;
                    margin-left: 1.4rem;
                    padding: 0.7rem 2.4rem;
                    border-color: ${colorSecondaryGrayLight};
                    color: ${colorSecondaryGrayLight};
                    background-color: transparent;
                    opacity: 0.6;
                    pointer-events: none;
                  `,
            }}
          />

        </div>
      </Controls>

      <Wrapper
        isGrow
        cssRules={wrapperCssRules}
      >
        <SingleTable
          entityName={entityName}
          data={[
            { id: 1,
              tableData: [],
              stylesTemplate: {
                rowStyles: `
                  &&{
                    margin:0;
                    border: none;
                    display: none;
                  }
                `,
              } },
          ]}
          model={exchangeRatesTableHeaderModel}
          orderRules={orderRules}
          changeOrder={changeOrder}
          tableName="exchangeRates"
          isFetching={false}
          withoutLoader
          controls={[]}
          cssRules={tableRules}
        />
        <DetailsForm
          withErrorBox
          formId="formId"
          entityName={entityName}
          isFormSubmitted={isFormSubmitted}
          isFetchingDetails={isFetching}
          content={exchangeRatesManagementFormContent}
          stylesTemplate={formWrapperStylesTemplate}
          mainControlsConfig={formMainControlsConfig}
          changeOrder={changeOrder}
          orderRules={orderRules}
          defaultControlsHidden
          withoutFormControls
          remoteControl={formsController}
        />
      </Wrapper>

      <GoTop />

    </>
  );
};

ExchangeRatesManagement.propTypes = {
  location: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  getExchangeRates: PropTypes.func.isRequired,
  updateExchangeRates: PropTypes.func.isRequired,
  exchangeRatesManagementFormContent: PropTypes.shape({

  }).isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
};

ExchangeRatesManagement.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  exchangeRatesManagementFormContent: selectExchangeRatesManagementFormContent,
});

const mapDispatchToProps = {
  getExchangeRates: configurationsActions.getExchangeRates,
  updateExchangeRates: configurationsActions.updateExchangeRates,
  changeOrder: commonActions.changeOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeRatesManagement);
