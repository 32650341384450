import {
  colorPrimary,
  colorSecondaryGray,
  colorWhite,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { STORE_KEYS } from 'core/billing-reports/constants';
import { COLUMN_TYPES, FILTERS_TYPES } from 'core/constants';
import { get } from 'lodash';
import moment from 'moment';

import { getHasPermissions } from 'utils/auth';
import { checkIsInclude, checkIsSelected } from 'utils/helpers/filters';

const {
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_WAW,
  ACC_NQZ,
  ACC_TBS,
} = USERS_GROUPS;

export const internalToListModelGetter = (
  userGroup,
  devcenters,
  customers,
  contracts
) => {
  const customersById = {};
  customers.forEach(({ value, label }) => {
    customersById[value] = label;
  });
  const isAudit = getHasPermissions(userGroup, [
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_KZH,
  ]);

  const isAccountant = getHasPermissions(userGroup, [
    ACC_WAW,
    ACC_NQZ,
    ACC_TBS,
  ]);

  return {
    rules: {
      css: {
        gridTemplateConfig: `
          top: 16rem;
          grid-template-columns:
          minmax(auto,35fr)
          minmax(auto,15fr)
          ${isAudit ? '' : 'minmax(auto,10fr)'}
          minmax(auto,20fr)
          minmax(auto,38fr)
          minmax(auto,12fr)
          minmax(auto,15fr);`,
      },
    },
    tableName: 'internalToReport',
    noDataMessage:
      'Sorry, no content matched your criteria. Please, change the selected filters.',
    rowStatusGetter: () => ({}),
    filtersTemplate: [
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.PROJECT_NAME,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, STORE_KEYS.PROJECT_NAME, '');
          return checkIsInclude({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.CUSTOMER,
        valueGetter: (row) => customersById[row.customer],
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, STORE_KEYS.CUSTOMER, '');
          return checkIsSelected({ selected, value: customersById[value] });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.DEVCENTER,
        shouldntBeRendered: isAudit,
        valueGetter: (row) => devcenters[row.devcenter],
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, STORE_KEYS.DEVCENTER, '');
          return checkIsSelected({ selected, value: devcenters[value] });
        },
      },
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.HIQO_TO,
        checkFilter: ({ currentRow, selected }) => {
          const { taskOrder, effectiveDate, devcenter, projectModel } =
            currentRow;
          const value = `${taskOrder} ${moment(effectiveDate).format('MMMM')} ${
            devcenters[devcenter]
          } ${projectModel}`;
          return checkIsInclude({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.SEARCH,
        storeKey: STORE_KEYS.HIQO_CONTRACT,
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, STORE_KEYS.HIQO_CONTRACT, '');
          return checkIsInclude({ selected, value: contracts[value] });
        },
      },
      {
        type: FILTERS_TYPES.SELECT,
        storeKey: STORE_KEYS.PROJECT_MODEL,
        valueGetter: (row) => get(row, STORE_KEYS.PROJECT_MODEL, ''),
        checkFilter: ({ currentRow, selected }) => {
          const value = get(currentRow, STORE_KEYS.PROJECT_MODEL, '');
          return checkIsSelected({ selected, value });
        },
      },
      {
        type: FILTERS_TYPES.EMPTY,
      },
    ],
    dataTemplate: [
      {
        columnName: 'Project Name',
        valueGetter: ({ projectName, clientId }) => ({
          type: !isAudit && !isAccountant ? 'link' : 'text',
          componentProps: {
            data: projectName,
            pathname: `/clients/${clientId}/customerss`,
          },
        }),
      },
      {
        columnName: 'Customer',
        valueGetter: ({ customer }) => ({
          type: COLUMN_TYPES.TEXT,
          value: customersById[customer],
        }),
      },
      {
        columnName: 'Dev Center',
        shouldntBeRendered: isAudit,
        valueGetter: ({ devcenter }) => ({
          type: COLUMN_TYPES.TEXT,
          value: devcenters[devcenter],
        }),
      },
      {
        columnName: 'HiQo TO',
        valueGetter: ({
          taskOrder,
          effectiveDate,
          devcenter,
          projectModel,
        }) => ({
          type: COLUMN_TYPES.TEXT,
          value: `${taskOrder} ${moment(effectiveDate).format('MMMM')} ${
            devcenters[devcenter]
          } ${projectModel}`,
        }),
      },
      {
        columnName: 'HiQo Contract',
        valueGetter: ({ internalContract }) => ({
          type: COLUMN_TYPES.TEXT,
          value: contracts[internalContract],
        }),
      },
      {
        columnName: 'Project Model',
        valueGetter: ({ projectModel }) => ({
          type: COLUMN_TYPES.TEXT,
          value: projectModel,
        }),
      },
      {
        columnName: 'Actions',
        withoutControls: true,
        valueGetter: ({
          clientId,
          taskOrder,
          taskOrderId,
          projectName,
          customer,
          devcenter,
          internalContract,
          projectModel,
          effectiveDate,
          dateTo,
        }) => ({
          type: COLUMN_TYPES.ACTION,
          componentProps: {
            data: 'Excel',
            withBody: true,
            actionName: 'generateInternalToExcelReport',
            actionArguments: {
              fields: {
                clientId,
                taskOrder,
                taskOrderId,
                projectName,
                customer,
                devcenter,
                internalContract,
                projectModel,
                effectiveDate,
                dateTo,
              },
              year: moment(effectiveDate).year(),
              month: moment(effectiveDate).month() + 1,
            },
            cssRules: `
            && {
              width: 100%;
              padding: 0;
              margin: 0 1rem;
              font-size: 1rem;
              line-height: 1.6rem;
              text-align: center;
              color: ${colorSecondaryGray};
              border-color: ${colorSecondaryGray};
              background-color: transparent;
              &:hover {
                color: ${colorWhite};
                background-color: ${colorPrimary};
              }
              color: ${colorPrimary};
              border-color: ${colorPrimary};
            }
          `,
          },
        }),
      },
    ],
  };
};
