import { USERS_GROUPS } from 'core/auth/constants';
import { COLUMN_TYPES } from 'core/constants';
import { get } from 'lodash';
import moment from 'moment';
import { string, date as yupDate } from 'yup';

import { tableWrapperCssRules } from './styles';

const { CPS } = USERS_GROUPS;
const gridTemplateConfig = `{
  padding: 0;
  margin-top: 0;
  grid-column-gap: 0.8rem;
  grid-template-columns: minmax(auto,25rem) minmax(auto, 15rem) minmax(auto, 20rem)  minmax(auto, 6rem);
}`;

export const holidaysListModelGetter = (devcenters, userGroup) => {
  const allDevCenters = get(devcenters, 'devCenters', []);
  const isCPS = CPS === userGroup;
  return {
    rules: {
      css: {
        tableWrapperCssRules,
        gridTemplateConfig,
        headerCssRules: `
        ${gridTemplateConfig}
        position: sticky;
        top: 11.6rem;
        z-index: 1;
        background-color: #f2f2f2;
      `,
      },
    },
    tableName: 'holidays',
    rowStatusGetter: () => ({}),
    dataTemplate: [
      {
        columnName: 'Delivery Center',
        valueGetter: ({ devcenter }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: get(allDevCenters.find((item) => item.devcenterId === devcenter), 'name', '--'),
            cssRules: `
            font-size: 1.5rem;
          `,
          },
        }),
        cssRules: '',
      },
      {
        columnName: 'Upload Date',
        valueGetter: ({ date = null }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            data: date,
            cssRules: `
            font-size: 1.5rem;
          `,
          },
        }),
      },
      {
        columnName: 'Description',
        valueGetter: ({ description = null }) => ({
          type: COLUMN_TYPES.TEXT,
          componentProps: {
            title: description,
            data: description,
            cssRules: `
            font-size: 1.5rem;
          `,
          },
        }),
      },
      {
        columnName: 'Action',
        valueGetter: ({ recordId = null, date }) => ({
          type: COLUMN_TYPES.ACTION,
          actionName: 'openModal',
          componentProps: {
            data: 'Delete',
            actionName: 'openModal',
            isHidden: isCPS,
            actionArguments: {
              currentModal: 'CONFIRM_ACTION_MODAL',
              content: [
                {
                  type: 'title',
                  data: `Delete this position record start date ${moment(
                    date
                  ).format('L')}?`,
                },
                {
                  type: 'description',
                  data: 'This file will be permanently deleted and cannot be restored.',
                },
              ],
              acceptActionName: 'removeHolidaysRecord',
              acceptActionTitle: 'Delete',
              acceptActionArguments: {
                recordId,
              },
            },
          },
        }),
        cssRules: '',
        withoutControls: true,
      },
    ],
  };
};

export const holidaysFormTemplate = (
  holidaysSelectedYear = moment().year(),
  devcenters
) => [
  {
    stylesTemplate: {
      cssRules: gridTemplateConfig,
    },
    isTableView: true,
    formData: [
      {
        type: 'select',
        name: 'devcenter',
        withLabel: false,
        placeholder: 'Choose Delivery Center',
        valueGetter: ({ newHoliday }) => ({
          selected: newHoliday.devcenter || '',
          items: devcenters.forSelect,
        }),
        cssRules: `{
        && .select-item__control {
          font-size: 1.5rem;
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }
      }
      `,
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().nullable().required('Required field'),
        },
      },
      {
        type: 'calendar',
        name: 'date',
        valueGetter: ({ newHoliday }) => newHoliday.date || null,
        currentDate: moment().year(holidaysSelectedYear),
        withCurrentDate: false,
        minYear: holidaysSelectedYear,
        maxYear: holidaysSelectedYear,
        maxDetails: 'year',
        minDetails: 'month',
        popperProps: {
          placement: 'bottom-end',
        },
        outputFormatting: ({ date }) => moment.isMoment(date) ? date.format('YYYY-MM-DD') : date,
        validationRules: {
          schemaGetter: () => yupDate()
            .nullable()
            .max(
              `${holidaysSelectedYear}-12-31`,
              () => `Date must be included in ${holidaysSelectedYear} year`
            )
            .min(
              `${holidaysSelectedYear}-01-01`,
              () => `Date must be included in ${holidaysSelectedYear} year`
            )
            .required('Required field'),
        },
        cssRules: `{
        font-size: 1.5rem;
        max-height: unset;
      }`,
      },
      {
        type: 'text',
        name: 'description',
        withLabel: false,
        placeholder: 'Description',
        valueGetter: ({ newHoliday }) => newHoliday.description || '',
        validationRules: {
          schemaGetter: () => string().required('Required field'),
        },
        cssRules: `{
        font-size: 1.5rem;
      }`,
      },
      {
        type: 'actionButton',
        data: 'Delete',
        actionName: 'setCreateMode',
        actionArguments: { createMode: false },
        cssRules: `
        && {font-size: 1.2rem;}
      `,
      },
      {
        type: 'virtualField',
        name: 'year',
        valueGetter: ({ newHoliday }) => newHoliday.year,
      },
    ],
  },
];
