import { formHeaderCss } from 'assets/styles/config';

export const listHeadingDataGetter = ({
  hasPermissionsToUpdate,
  isResourceManagers,
  withResourceName,
  isSalaryHidden,
  isAccSPB,
}) => (
  [
    {
      isHidden: !withResourceName,
      value: {
        title: 'Resource',
        cssRules: `
        ${formHeaderCss}
        text-align: left;
        padding-left: 1.6rem;
      `,
      },
    },
    {
      value: {
        title: 'Valid from',
        cssRules: `
        ${formHeaderCss}
        text-align: left;
        ${withResourceName ? '' : 'padding-left: 1.6rem;'}
      `,
      },
    },
    {
      value: {
        title: 'Dev Center',
        cssRules: `
        ${formHeaderCss}
        ${hasPermissionsToUpdate && !isSalaryHidden ? 'line-height: 14px; padding: 1rem 1rem 1.4rem 0;' : ''}
        text-align: left;
      `,
      },
    },
    {
      value: {
        title: 'Title',
        cssRules: `
        ${formHeaderCss}
        text-align: left;
      `,
      },
    },
    {
      value: {
        title: 'Seniority',
        cssRules: `
        ${formHeaderCss}
        text-align: left;
      `,
      },
    },
    {
      value: {
        title: 'Int Sen.',
        cssRules: `
        ${formHeaderCss}
        padding-right: 0;
        text-align: left;
      `,
      },
    },
    {
      value: {
        title: 'PLL',
        cssRules: `
        ${formHeaderCss}
        text-align: left;
      `,
      },
    },
    {
      value: {
        title: `Hrs/${hasPermissionsToUpdate && !isSalaryHidden ? ' ' : ''}day`,
        cssRules: `
        ${formHeaderCss}
        ${hasPermissionsToUpdate && !isSalaryHidden ? 'line-height: 14px; padding: 1rem 0 1.4rem;' : ''}
        text-align: left;
      `,
      },
    },
    {
      value: {
        title: 'Prob.',
        cssRules: `
        ${formHeaderCss}
        text-align: left;
      `,
      },
    },
    {
      value: {
        title: 'End of probation',
        cssRules: `
        ${formHeaderCss}
        text-align: left;
        line-height: ${hasPermissionsToUpdate ? '2.1rem' : '1.4rem'};
      `,
      },
    },
    {
      value: {
        title: 'USD',
        cssRules: `
        ${formHeaderCss}
        padding-right: 0;
      `,
      },
      isHidden: !hasPermissionsToUpdate || isSalaryHidden,
    },
    {
      value: {
        title: 'PLN',
        cssRules: `
        ${formHeaderCss}
        padding-right: 0;
      `,
      },
      isHidden: !hasPermissionsToUpdate || isSalaryHidden,
    },
    {
      value: {
        title: 'RUR',
        cssRules: `
        ${formHeaderCss}
        padding-right: 0;
      `,
      },
      isHidden: !hasPermissionsToUpdate || isSalaryHidden,
    },
    {
      value: {
        title: 'Action',
        cssRules: `
        ${formHeaderCss}
        text-align: center;
        padding-right: 0;
      `,
      },
    },
    {
      value: {
        title: 'Dev Center Director appr.',
        cssRules: `
        ${formHeaderCss}
        text-align: center;
        padding: 1.6rem 1.1rem 1.4rem;
        line-height: 1.4rem;
      `,
      },
      isHidden: !hasPermissionsToUpdate,
    },
    {
      value: {
        title: 'CEO appr.',
        cssRules: `
        ${formHeaderCss}
        text-align: center;
        padding-right: 0;
      `,
      },
      isHidden: !hasPermissionsToUpdate,
    },
    {
      value: {
        title: 'Approval Status',
        cssRules: `
        ${formHeaderCss}
        text-align: center;
        padding-right: 0;
      `,
      },
      isHidden: isAccSPB ? false : (hasPermissionsToUpdate || isSalaryHidden) && !isResourceManagers,
    },
  ]
);
