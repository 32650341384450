import React from 'react';

import { PlusIcon } from 'assets/svgComponents';
import { USERS_GROUPS } from 'core/auth/constants';
import {
  devCenterDirectors,
  updateWBDetails,
} from 'core/auth/guaranteedAccessRoles';

import {
  createDeleteRecordAction,
  createInfoCard,
  generateHistoryRecord,
  generateDevCentersListForSelect,
  hasSalary,
  shouldShowInfoCard,
  generateFullHistory,
  generatePartialGridTemplateColumnsConfig,
  getCssGrids,
  getFormStyles,
  generateEmptyRecord,
  getFilteredDevCentersForSelect,
} from 'layouts/employee-details/model/utils/workbook';

import { get, findKey } from 'lodash';

import moment from 'moment';
import { getHasPermissions } from 'utils/auth';
import { getBusinessRoles } from 'utils/helpers/getBusinessRoles';

import {
  listHeadingDataGetter,
  listHeadingStyles,
} from './config/utils/workbook';

import {
  additionalControlsConfigGetter,
  workBookFormGetter,
} from './config/work-book-config';

import {
  createWorkBookFormGetter,
} from './config/сreate-work-book-config';

const {
  CEO,
  CTO,
  CPS,
  HR,
  HRM,
  RM,
  ACC_SPB,
} = USERS_GROUPS;

export default ({
  userGroup,
  userData: { userId },
  employeeDetails: {
    dateHired,
    devstaffId,
    isDismissed,
    workBookRecords = [],
    internshipStartDate,
    dateLast: dismissalDate,
    isIntern: isInternRecord,
    titlesFromDevstaffCompensation,
    isNew,
  },
  history,
  devcenters,
  devCentersBySystemRole,
  devcentersAll,
}) => {
  const {
    dateLast,
    rehireDate,
    vacationEndDate,
    archivedRecords,
    vacationStartDate,
  } = history;
  const archivedRehiredRecord = archivedRecords.find((record) => record.isRehire);
  const archivedRehiredDate = get(archivedRehiredRecord, 'dateStart', null);

  const isCEO = getHasPermissions(userGroup, [CEO]);
  const isCPS = getHasPermissions(userGroup, [CPS]);
  const isHr = getHasPermissions(userGroup, [HR]);
  const isAccSPB = getHasPermissions(userGroup, [ACC_SPB]);
  const isResourceManagers = getHasPermissions(userGroup, [HRM, RM]);
  const hasPermissionsToUpdate = getHasPermissions(userGroup, updateWBDetails([CTO, CPS]));
  const devcentersListForSelect = generateDevCentersListForSelect(devcenters);
  const filteredDevCentersForSelect = getFilteredDevCentersForSelect(devcentersListForSelect, findKey(USERS_GROUPS, (user) => user === userGroup), devCentersBySystemRole);
  const isOneDevCenterId = filteredDevCentersForSelect.length === 2 ? get(filteredDevCentersForSelect, '[1].value', '') : '';
  const isSalaryHidden = !hasPermissionsToUpdate || isHr;
  const { gridTemplateColumns } = getCssGrids(hasPermissionsToUpdate, isSalaryHidden);
  const formStyles = getFormStyles(getCssGrids(hasPermissionsToUpdate, isSalaryHidden));
  const partialGridTemplateColumnsConfig = generatePartialGridTemplateColumnsConfig(hasPermissionsToUpdate, isSalaryHidden);

  const fullHistory = generateFullHistory(workBookRecords, archivedRecords, {
    dismissalDate: dateLast,
    rehireDate,
    startDate: vacationStartDate,
    endDate: vacationEndDate,
  });

  const firstWBRecord = workBookRecords[workBookRecords.length - 1];
  const lastApprovedRecord = workBookRecords.find((record) => record.approvedCeo && record.approvedDd);
  const lastTrueRecord = workBookRecords.find((record) => record.approvedCeo && record.approvedDd && moment().isSameOrAfter(record.validFrom));
  const devCenterByFirstRecord = get(workBookRecords[workBookRecords.length - 1], 'devcenter', null);
  const devCenterByLastRecord = get(workBookRecords[0], 'devcenter', null);

  const businessRolesForLastRecord = getBusinessRoles(devCentersBySystemRole, devCenterByLastRecord);
  const isBusinessRoleForLastRecord = businessRolesForLastRecord.includes(userGroup);
  const defaultDevCenter = get(lastTrueRecord, 'devcenter', devCenterByFirstRecord);
  const owners = getBusinessRoles(devCentersBySystemRole, defaultDevCenter);
  const isOwner = owners.includes(userGroup);

  const emptyRecord = generateEmptyRecord({
    title: get(lastTrueRecord, 'title', ''),
    devcenter: get(lastTrueRecord, 'devcenter', isOneDevCenterId),
    plLevel: get(lastTrueRecord, 'plLevel', ''),
    dateHired,
  });

  if (!fullHistory.length && hasPermissionsToUpdate) {
    fullHistory.push(emptyRecord);
  }

  const isFirstRecordTemplate = !get(fullHistory, '[0].recordId', false) && !get(fullHistory, '[0].historyId', false);
  const isHiddenForDevOfficeAdmin = ((!isCEO && !isHr) && (!isBusinessRoleForLastRecord && !isOwner));
  const isHiddenAddRecordButton = !lastApprovedRecord || !hasPermissionsToUpdate || isCPS || (isHr && !isNew) || isFirstRecordTemplate || isHiddenForDevOfficeAdmin;
  const isMoreThanOneRecord = fullHistory.length > 1;

  const content = fullHistory.map((record) => {
    const isFirstRecord = get(record, 'recordId', null) === get(firstWBRecord, 'recordId', null);
    const businessRolesForCurrentRecord = getBusinessRoles(devCentersBySystemRole, record.devcenter);
    const isBusinessRoleForCurrentRecord = businessRolesForCurrentRecord.includes(userGroup);
    const isDevCenterDirector = getHasPermissions(userGroup, devCenterDirectors([...businessRolesForCurrentRecord])) && isBusinessRoleForCurrentRecord;

    const additionalRoles = [[isOwner, owners], [isBusinessRoleForLastRecord, businessRolesForLastRecord]].reduce((accum, [condition, roles]) => condition ? accum.concat(roles) : accum, []);

    const hasPermissionsToUpdateByBusinessRole = getHasPermissions(userGroup, updateWBDetails([...additionalRoles].filter(Boolean)));

    if (record && get(record, 'dateStart', null)) {
      return generateHistoryRecord(record);
    }

    const deleteRecordAction = createDeleteRecordAction(record, devstaffId);
    const inEditMode = !record || get(record, 'inEditMode', false);
    const isHistoryRecord = (record.approvedCeo || false) && (record.approvedDd || false);
    const hasNoSalaryInfo = isDevCenterDirector && get(record, 'seniority', '') !== 'Intern' && !hasSalary(record);
    const isInfoCard = shouldShowInfoCard(inEditMode, isHistoryRecord, hasPermissionsToUpdateByBusinessRole, record, isDevCenterDirector, isCEO, isHr, isNew);
    const isRelocate = !isBusinessRoleForCurrentRecord;
    const isFilteredDevCenters = isOwner && !isFirstRecord;
    const isArchivedDevCenter = !devcentersListForSelect.some((item) => item.value === record.devcenter);

    if (!get(record, 'recordId', '')) {
      return createWorkBookFormGetter({
        partialGridTemplateColumnsConfig,
        titlesFromDevstaffCompensation,
        hasPermissionsToUpdate,
        internshipStartDate,
        isInternRecord,
        dismissalDate,
        isDismissed,
        rehireDate: archivedRehiredDate || get(rehireDate, 'value', ''),
        formStyles,
        devcentersListForSelect: devstaffId ? devcentersListForSelect : filteredDevCentersForSelect,
        dateHired,
        dateLast,
        isCEO,
        isCPS,
        devstaffId,
        isMoreThanOneRecord,
        record: emptyRecord,
        isSalaryHidden,
        isArchivedDevCenter,
      });
    }

    const additionalControlsConfig = additionalControlsConfigGetter({
      hasNoSalaryInfo,
      hasPermissionsToUpdate,
      isHistoryRecord,
      devstaffId,
      isCEO,
      isDevCenterDirector,
      isRelocate,
      record,
      userId,
    });

    if (isInfoCard) {
      return createInfoCard({
        isInfoCard,
        dateHired,
        record,
        devcenters,
        devcentersAll,
        hasPermissionsToUpdate,
        hasPermissionsToUpdateByBusinessRole,
        isHistoryRecord,
        additionalControlsConfig,
        deleteRecordAction,
        userGroup,
        isNew,
        isSalaryHidden,
      });
    }

    return workBookFormGetter({
      partialGridTemplateColumnsConfig,
      titlesFromDevstaffCompensation,
      additionalControlsConfig,
      hasPermissionsToUpdate,
      internshipStartDate,
      deleteRecordAction,
      isHistoryRecord,
      isInternRecord,
      dismissalDate,
      isDismissed,
      inEditMode,
      rehireDate: archivedRehiredDate || get(rehireDate, 'value', ''),
      formStyles,
      devcentersListForSelect: isFilteredDevCenters ?
        devcentersListForSelect :
        filteredDevCentersForSelect,
      dateHired,
      dateLast,
      isCEO,
      isDevCenterDirector,
      isCPS,
      record,
      userId,
      isSalaryHidden,
      hasNoSalaryInfo,
      isArchivedDevCenter,
    });
  });
  if (content.length === 0) {
    return [
      {
        type: 'heading',
        data: 'Work book',
        cssRules: '\n      display: inline-block;\n      margin: 1.6rem;\n      line-height: 2.4rem;\n      font-size: 1.8rem;\n    ',
      },
      {
        type: 'heading',
        data: 'This section currently contains no data.',
        cssRules: '\n      margin: 1.4rem 0 0 1.6rem;\n      font-size: 1.6rem;\n      line-height: 2.4rem;\n      color: #bbbdc0;\n    ',
      },
    ];
  }
  return [
    {
      type: 'data-list',
      title: 'Work Book',
      headersConfig: {
        stylesTemplate: {
          cssRules: `
          ${listHeadingStyles}
          grid-template-columns: ${gridTemplateColumns};
          z-index: 1;
        `,
        },
        data: listHeadingDataGetter({
          hasPermissionsToUpdate,
          isSalaryHidden,
          isResourceManagers,
          isAccSPB,
        }),
      },
      controls: [
        {
          title: 'Add record',
          getIsHidden: () => isHiddenAddRecordButton,
          withIcon: true,
          icon: <PlusIcon />,
          onClick: ({ actions }) => actions.addWorkBookTemplateRecord &&
            actions.addWorkBookTemplateRecord(emptyRecord),
          cssRules:
            isDismissed && !rehireDate ?
              `
          color: #bbbdc0;
          background: transparent;
          border-color: #bbbdc0;
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.6;
        ` :
              '',
        },
      ],
      content,
    },
  ];
};
